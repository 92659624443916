import React, {useEffect, useRef} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"

const NosotrosPage = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Politicas de privacidad"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                         style={{backgroundImage: 'url("/images/lca/header1.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">

                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
                                    Política de privacidad</h2>
                            </div>

                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="down-section">
                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-12 ">
                                <p>Latam Capital Asesoria e Infraestructura, S.C. (“LCA” o “Nosotros”) presta la debida atención a las
                                    cuestiones relacionadas con la privacidad en Internet. A continuación se presenta la
                                    Política de Privacidad correspondiente a este sitio de Internet (el “Sitio”). La
                                    Política de Privacidad desarrollada a continuación detalla la forma mediante la cual
                                    recopilamos información relacionada a usted en el Sitio, el uso de la misma y la
                                    manera mediante la cual puede comunicarse con Nosotros en relación a dicha
                                    información. Consideramos de relevante importancia tenga conocimiento del manejo que
                                    le damos a la información recibida en el Sitio de usted.</p>
                                <p>Recomendamos que en adición a leer con detenimiento las presentes Políticas de
                                    Privacidad, favor de presionar aquí para leer nuestros Términos y Condiciones, los
                                    cuales explican la manera mediante la cual se utiliza el presente Sitio.</p>

                                <h6 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    INFORMACIÓN QUE RECOPILAMOS
                                </h6>
                                <p><b>Información de identificación personal</b>. Para poder utilizar ciertas
                                    características del Sitio, podremos solicitarle nos proporcione información de
                                    identificación personal, como por ejemplo su nombre, código postal, dirección de
                                    correo electrónico, número telefónico, facsímil, número de identificación del
                                    cliente, historial personal u otro tipo de información de identificación personal
                                    (“Información de Identificación Personal”). Asimismo, en el supuesto de ingresar a
                                    partes de este Sitio que se encuentren protegidas mediante contraseña (a) una vez
                                    enviada su contraseña e ingrese al Sitio, el mismo reconocerá quién es usted y
                                    recopilará toda la información enviada por usted, incluida toda la información
                                    electrónica (lo cual incluye toda la información relacionada con la operación), y
                                    (b) cualquier tipo de información recopilada relacionada a usted a través de este
                                    Sitio podrá asociarse con otra información de identificación que tengamos de usted.
                                    Toda la información que usted proporcione a través del Sitio será enviada a
                                    servidores ubicados en Estados Unidos y será almacenada en dichos servidores.</p>

                                <p>Podríamos recopilar, asimismo, Nosotros (incluyendo nuestras afiliadas) y nuestros
                                    socios comerciales podremos utilizar Información de Identificación Personal para
                                    responder a su consulta, enviarle por correo electrónico contenidos o alertas (lo
                                    cual incluye actualizaciones del Sitio, de los Términos y Condiciones, o de las
                                    presentes Políticas de Privacidad) o permitirle acceder a información específica
                                    sobre la cuenta. En adición a lo anterior, podremos utilizar cualquier Información
                                    de Identificación Personal enviada en respuesta a nuestros anuncios de empleo
                                    durante nuestro proceso de revisión, lo cual podría incluir la divulgación a
                                    afiliadas y a terceros que nos ayuden con nuestra revisión.</p>

                                <p>Es importante mencionar que intentamos por todos los medios posibles utilizar medidas
                                    administrativas, técnicas, de personal y físicas para proteger la información de
                                    identificación personal que se encuentre en nuestro poder contra la posibilidad de
                                    pérdida, robo y uso no autorizado, divulgación o modificación. </p>

                                <p><b>Información de identificación no personal</b>. En general, usted puede visitar el
                                    Sitio
                                    sin que se le solicite revelar ningún tipo de Información de Identificación
                                    Personal. Sin embargo, nuestros servidores de Internet recopilan cierta información
                                    que no le identifica a usted de manera personal. Por ejemplo, recopilamos y
                                    registramos la dirección de Protocolo de Internet correspondiente a la computadora
                                    de un visitante (la cual es la dirección de su computadora en Internet), el
                                    navegador que utiliza un visitante (por ejemplo, Internet Explorer), el sistema
                                    operativo (por ejemplo, Windows) y los nombres de dominio que usted ha visitado
                                    previos a ingresar al Sitio y posterior a su abandono. Esta información se agrupa
                                    para medir estadísticas sobre nuestros visitantes, como por ejemplo el número de
                                    visitas al Sitio, el tiempo promedio transcurrido dentro del Sitio y las páginas
                                    visitadas en el Sitio. Utilizamos esta información para monitorear el uso del Sitio
                                    y para mejorar los contenidos del mismo. Salvo por ciertas divulgaciones permitidas
                                    a las autoridades encargadas de imponer el cumplimiento de la ley (comentadas
                                    posteriormente) y ciertas características técnicas del Sitio (por ejemplo, el
                                    mantenimiento del estado de la sesión), no combinamos dicha información con
                                    Información de Identificación Personal, únicamente es utilizada de manera
                                    grupal.</p>

                                <h6 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    INFORMACIÓN ALMACENADA EN SU COMPUTADORA
                                </h6>

                                <p>Podríamos enviar archivos de texto (por ejemplo, “cookies” u otros archivos
                                    almacenados en memoria caché) o imágenes a su navegador de Internet para almacenar
                                    información en su computadora. Dichos archivos de texto e imágenes se utilizan por
                                    comodidad técnica para almacenar información en su computadora. Por ejemplo,
                                    podríamos utilizar una sesión cookie para almacenar información que usted haya
                                    ingresado en un formulario, con el fin de que posteriormente no exista la necesidad
                                    de volver a ingresar dicha información. Podríamos utilizar información almacenada en
                                    dichos archivos de texto e imágenes para personalizar su experiencia en el Sitio y
                                    monitorear el uso del Sitio. Usted podrá configurar su navegador para que notifique
                                    cuando reciba un cookie. Un gran número de navegadores de Internet le permiten
                                    bloquear las cookies.</p>

                                <h6 className="alt-font font-weight-500 text-extra-dark-gray">
                                    DIVULGACIÓN DE INFORMACIÓN DE IDENTIFICACIÓN PERSONAL
                                </h6>
                                <p><b>Divulgación a Afiliadas y Socios</b>. Podríamos compartir su Información de
                                    Identificación Personal con nuestras afiliadas y socios, los cuales presenten
                                    servicios en nuestro nombre. A modo de ejemplo y sin limitación alguna, nuestros
                                    socios comerciales podrían enviarle alertas por correo electrónico en el caso de que
                                    se haya inscrito para recibir dicha información. Exigimos a nuestras afiliadas y
                                    socios que mantengan la confidencialidad de su información de identificación
                                    personal. Cualquier tercero al cual le suministremos Información de Identificación
                                    Personal recibirá la indicación de utilizarla únicamente de conformidad con las
                                    presentes Políticas de Privacidad y según lo permitan las leyes y regulaciones
                                    aplicables. Salvo que usted previamente nos autorice , o en el supuesto de que las
                                    leyes aplicables nos exijan, o que se especifique en las presentes Políticas de
                                    Privacidad, no publicaremos su Información de Identificación Personal en el Sitio ni
                                    divulgaremos su Información de Identificación Personal a terceros que no sean las
                                    afiliadas o socios que presten servicios en nuestro nombre.</p>

                                <p><b>Divulgación en Relación con una Venta de LCA</b>. Sin perjuicio de ninguna de las
                                    disposiciones contenidas en las presentes Políticas de Privacidad, nos reservamos el
                                    derecho de transferir Información de Identificación Personal a un tercero sin
                                    autorización de usted en relación con una venta de acciones, fusión o consolidación,
                                    u otro tipo de transferencia de los bienes de LCA y/o de una afiliada o división, ya
                                    sea en forma voluntaria o de conformidad con la legislación aplicable, siempre y
                                    cuando dicho tercero acepte proteger la confidencialidad de su Información de
                                    Identificación Personal en forma coherente con las presentes Políticas de
                                    Privacidad.</p>

                                <p><b>Divulgación a las Autoridades encargadas de imponer el cumplimiento de la Ley</b>.
                                    Sin
                                    perjuicio de las disposiciones contenidas en las presentes Políticas de Privacidad,
                                    podríamos divulgar información de usted, lo cual incluye, entre otras cosas, su
                                    Información de Identificación Personal a las autoridades encargadas de imponer el
                                    cumplimiento la ley o a otros funcionarios o agencias gubernamentales de conformidad
                                    con la Ley Patriótica de Estados Unidos (u otro estatuto o norma aplicable, o en
                                    respuesta a un emplazamiento, orden de registro u orden judicial. Asimismo,
                                    podríamos divulgar su Información de Identificación Personal u otro tipo de
                                    información de usted si bajo nuestro leal saber y entender consideramos que dicha
                                    divulgación es adecuada de conformidad con una investigación oficial o en respuesta
                                    a una amenaza significativa a la salud o los bienes de una persona. Podríamos
                                    divulgar información de usted con el fin de cumplir los Términos y Condiciones de
                                    Uso y la Política de Uso Aceptable, para responder a reclamaciones de violaciones de
                                    derechos de terceros o para proteger los derechos de LCA. En la medida permitida por
                                    la ley, haremos todos los esfuerzos razonables para notificarle sobre cualquier
                                    solicitud de divulgación de su información tan pronto como resulte razonablemente
                                    factible luego de la recepción de la correspondiente solicitud.</p>

                                <h6 className="alt-font font-weight-500 text-extra-dark-gray">
                                    CORRECCIONES, ACTUALIZACIONES Y SOLICITUDES DE INFORMACIÓN
                                </h6>
                                <p>Una vez recibida por escrito su solicitud, le proporcionaremos una copia de toda la
                                    Información de Identificación Personal que hayamos recopilado de usted en el Sitio o
                                    a través del mismo. Mediante solicitud por escrito a LCA, usted podrá corregir
                                    cualquier Información de Identificación Personal errónea o solicitar que LCA elimine
                                    dicha Información de Identificación Personal. Dicha solicitud deberá ser enviada
                                    a: <br/>
                                    <a href="mailto:info@latamcapitaladvisors.com"
                                       target="_blank">Info@Latamcapitaladvisors.com</a></p>
                                <p>LCA se reserva el derecho de confirmar su identidad antes de divulgarle cualquiera de
                                    dichos datos de Información de Identificación Personal. En el supuesto de solicitar
                                    alertas, información para inversionistas u otro tipo de información por correo
                                    electrónico y nos proporciona su dirección de correo electrónico, podremos enviarle
                                    dicha información de manera periódica. En el supuesto de no desear recibir dichos
                                    mensajes de correo electrónico, podrá optar por rechazar la recepción de los mismos
                                    escribiendo a:
                                    <br/><a href="mailto:info@latamcapitaladvisors.com"
                                            target="_blank">Info@Latamcapitaladvisors.com</a></p>

                                <h6 className="alt-font font-weight-500 text-extra-dark-gray">
                                    SITIOS DE TERCEROS
                                </h6>
                                <p>El Sitio puede contener enlaces a sitios de Internet operados por terceros no
                                    afiliados. LCA no asume responsabilidad por el contenido ni por las prácticas de
                                    privacidad que empleen otros sitios. Favor de leer los términos y condiciones de uso
                                    y la política de privacidad del sitio de Internet de destino.</p>
                                <h6 className="alt-font font-weight-500 text-extra-dark-gray">
                                    NOTIFICACIÓN PARA RESIDENTES DE CALIFORNIA – DERECHOS DE PRIVACIDAD DE CALIFORNIA
                                </h6>
                                <p>Si usted es residente de California, tiene el derecho de recibir: (a) información que
                                    identifique que hayamos divulgado a cualquier compañía de terceros dentro del último
                                    año, información personal correspondiente a usted y a su familia para fines de
                                    mercadeo directo de dicha compañía; y (b) una descripción de las categorías de
                                    información personal divulgadas. Para obtener dicha información envíe una solicitud
                                    por escrito a: <br/>
                                    <a href="mailto:info@latamcapitaladvisors.com"
                                       target="_blank">Info@Latamcapitaladvisors.com</a></p>
                                <h6 className="alt-font font-weight-500 text-extra-dark-gray">
                                    COMUNÍQUESE CON NOSOTROS
                                </h6>
                                <p>Si tiene preguntas específicas sobre nuestra Política de Privacidad, los Términos y
                                    Condiciones de Uso o la Política de Uso Aceptable, comuníquese con el Oficial de
                                    Seguridad de Tecnología de la Información de LCA escribiendo a:</p>
                                <p>Torre Optima 1 <br/>
                                    Av. Paseo de las Palmas No. 405-404 <br/>
                                    Lomas de Chapultepec <br/>
                                    C.P. 11000, Ciudad de México, México <br/>
                                    52-55-5202-6080 <br/>
                                    o a: <a href="mailto:info@latamcapitaladvisors.com"
                                            target="_blank">Info@Latamcapitaladvisors.com</a></p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

export default NosotrosPage
